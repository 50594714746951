import { render, staticRenderFns } from "./DynamicDataTable.vue?vue&type=template&id=42b70588&scoped=true"
import script from "./DynamicDataTable.vue?vue&type=script&lang=js"
export * from "./DynamicDataTable.vue?vue&type=script&lang=js"
import style0 from "./DynamicDataTable.vue?vue&type=style&index=0&id=42b70588&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b70588",
  null
  
)

export default component.exports