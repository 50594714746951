<template>
  <div>
    <template v-if="item[column]">
      <a
        v-if="showPreview"
        class="link"
        @click="previewDocument(item[column].id)"
      >
        {{ getDocumentCellValue(item[column]) }}
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              color="primary"
              dark
              v-bind="attrs"
              class="copy-link mr-2"
              v-on="on"
              @click.stop="downloadDocument(item[column].id)"
            >
              mdi-download
            </v-icon>
          </template>
          <span>Download file</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              color="primary"
              v-bind="attrs"
              small
              class="copy-link"
              v-on="on"
              @click.stop="copyLink"
              >mdi-content-copy
            </v-icon>
          </template>
          <span>Copy link to file</span>
        </v-tooltip>
      </a>
      <a v-else class="link" @click="downloadDocument(item[column].id)">
        {{ getDocumentCellValue(item[column]) }}
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              small
              class="copy-link"
              v-on="on"
              @click.stop="copyLink"
              >mdi-content-copy
            </v-icon>
          </template>
          <span>Copy link to file</span>
        </v-tooltip>
      </a>
    </template>
    <template v-else>-</template>
  </div>
</template>
<script>
import FileHandlerService from '@/services/file-handler';
import { getProject } from '@/services/api/projects.api';

export default {
  name: 'DocumentCell',
  props: {
    item: {
      type: Object,
      required: true,
    },
    column: {
      type: String,
      required: true,
    },
    showPreview: {
      type: Boolean,
      default: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
  },
  methods: {
    async copyLink() {
      const project = await getProject(this.projectId);

      let link = `${window.location.origin}/project/${project.slug}/tables/${
        this.tableId
      }?document=${this.item[this.column].id}`;
      navigator.clipboard.writeText(link).then(
        () => {
          this.$store.commit('showNotification', {
            content: 'Link copied to clipboard',
            color: 'success',
          });
        },
        (err) => {
          console.error('Async: Could not copy to clipboard', err);
        }
      );
    },
    downloadDocument(documentId) {
      if (this.tableId !== undefined && this.projectId !== undefined) {
        FileHandlerService.downloadRecordDocument(
          documentId,
          this.projectId,
          this.tableId
        );
      }
    },
    async previewDocument(documentId) {
      if (this.tableId !== undefined && this.projectId !== undefined) {
        let blob = await FileHandlerService.fetchRecordDocument(
          documentId,
          this.projectId,
          this.tableId
        );
        if (blob.type === 'application/pdf' || blob.type.startsWith('image/')) {
          this.$emit('previewFile', URL.createObjectURL(blob));
        } else {
          this.$store.commit('showNotification', {
            content: 'Preview function only allows images and pdf files',
            color: 'error',
          });
        }
      }
    },
    getDocumentCellValue(document) {
      if (document !== undefined && document !== null) {
        return `${document.name}.${document.extension}`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
.link {
  .copy-link {
    opacity: 0;
  }

  &:hover {
    .copy-link {
      opacity: 1;
    }
  }
}
</style>
